import './App.css';
import './style.css';
import Image from "./images/bridge.png"
import Dworld from "./logos/d-world-2.png"
import Dnft from "./logos/D-NFT.png"
import Dswap from "./logos/D-Swap.png"
import Dwallet from "./logos/d-wallet.png"
import DAO from "./logos/dao.png"
import Lottery from "./logos/D-Lottery.png"
import Prediction from "./logos/prediction.png"
import Promote from "./logos/promote&refer.png"
import DCX from "./logos/Buy DCX.png"
import DChain from "./logos/D-Chain.png"
import PropelX from "./logos/PropelX.png"
import DUSD from "./logos/DUSD.png"
import ValidatorTool from "./logos/Validator-staking.png"
import CrosschainSwap from "./logos/Cross-chain-Swap.png"
import Farm from "./logos/Farm.png"
import StakeEarn from "./logos/Stake-Earn.png"
import Getx from "./logos/getx.png"
import DeFI from "./logos/defi.png"
import Galaxy from "./images/galaxy.mp4"
import Light from "./images/light.png"
import Stone from "./images/Stone.png"
import $ from "jquery"
import { Tooltip } from 'react-tooltip'
import React, { useState, useEffect } from 'react';

function App() {
  const [isActive, setIsActive] = useState(false);
  const [isActive2, setIsActive2] = useState(false);

  const toggleActiveClass = () => {
    setIsActive2(false);
    setIsActive(!isActive);
  };
  const toggleActiveClass2 = () => {
    setIsActive(false);
    setIsActive2(!isActive2);
  };
  useEffect(() => {
    if ($(window).width() < 992) {
      // change functionality for smaller screens
    } else {
      $(".d-world-top-1-1").on("mouseenter", function () {
        $(".d-w-overrall-top-section").css("transform", "scale(1)");
        $(".d-world-text-1").addClass("d-world-text-1-active");
      });
      $(".d-world-top-1-1").on("mouseleave", function () {
        setIsActive(false);
        setIsActive2(false);
        $(".d-w-overrall-top-section").css("transform", "scale(1.3)");
        $(".d-world-text-1").removeClass("d-world-text-1-active");
      });
    }


  });


  return (
    <div className="App">

      <div class="night">
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
        <div class="shooting_star"></div>
      </div>
      <img src={Light} alt='star' className='floating-start-1' />
      <img src={Light} alt='star' className='floating-start-2' />
      <img src={Light} alt='star' className='floating-start-3' />

      <img src={Stone} alt='star' className='floating-start-4 bonben-animation' />

      <div id="stars-group-1"></div>
      <div id="stars-group-2"></div>
      <div id="stars-group-3"></div>
      <div id="stars-group-4"></div>
      <div id="stars-group-5"></div>
      <div id="stars-group-6"></div>
      <video className="banner-video-1" autoPlay loop muted>
        <source src={Galaxy} type="video/mp4" />
      </video>

      <h1 className='d-world-text-1'>Certainly! Welcome to the D-Ecosystem Galaxy, where each application shines like a celestial planet in our crypto universe. Explore the orbit of innovation, powered by D-Chain technology, and discover the following stellar apps</h1>

      <section className='d-w-overrall-top-section'>
        <div className='d-world-top-1-1'>
          <Tooltip anchorSelect=".dao-tooltip" place="left" style={{ backgroundColor: "#FFF", color: "#222", width: "400px", zIndex: "999" }} clickable>
            <h6>DAOCENTER</h6>
            The DAOCENTER is a hub for decentralized autonomous organizations (DAOs) activities, offering users various ways to engage with the community and earn rewards - $DCX:
            <h6>Click planet to see DAO APPS</h6>
          </Tooltip>
          <Tooltip anchorSelect=".dwallet-tooltip" place="right" style={{ backgroundColor: "#FFF", color: "#222", width: "400px", zIndex: "999" }} clickable>
            <h6>D-WALLET </h6>
            D-WALLET is a secure and versatile digital wallet designed to store, send, and receive various cryptocurrencies. It provides users with full control over their private keys and ensures the safe management of digital assets. D-WALLET supports multiple cryptocurrencies, offering convenience and accessibility for managing a diverse portfolio.
            <h6>Click planet to explore</h6>
          </Tooltip>
          <Tooltip anchorSelect=".dnft-tooltip" place="right" style={{ backgroundColor: "#FFF", color: "#222", width: "400px", zIndex: "999" }} clickable>
            <h6>D-NFT (Coming Soon) </h6>
            D-WALLET is a secure and versatile digital wallet designed to store, send, and receive various cryptocurrencies. It provides users with full control over their private keys and ensures the safe management of digital assets. D-WALLET supports multiple cryptocurrencies, offering convenience and accessibility for managing a diverse portfolio.
          </Tooltip>
          <Tooltip anchorSelect=".dswap-tooltip" place="left" style={{ backgroundColor: "#FFF", color: "#222", width: "400px", zIndex: "999" }} clickable>
            <h6>D-SWAP </h6>
            <p className='mb-1'>D-SWAP is a decentralized exchange (DEX) platform that allows users to swap
              cryptocurrencies without the need for a centralized intermediary. This ensures
              secure, transparent, and efficient transactions with zero fees. D-SWAP leverages
              smart contract technology to facilitate trustless trades directly between users' wallets.</p>
            D-Swap quickly gained popularity in the DeFi community for lower fees and rewards
            for liquidity providers. D-Swap is a decentralised exchange (DEX) that operates
            using an automated market maker (AMM) system.Multi-Chain Support: D-Swap
            works with a number of blockchain networks, such as Ethereum, Binance Smart
            Chain, and others.
          </Tooltip>
          <Tooltip anchorSelect=".validator-tooltip" place="right" style={{ backgroundColor: "#FFF", color: "#222", width: "400px", zIndex: "999" }} clickable>
            <h6>Validator Staking </h6>
            The Validator Staking supports the network's security and integrity by enabling users to verify transactions and produce blocks. Validators, crucial to the blockchain's consensus mechanism, use this tool to participate in governance, secure the network, and earn rewards ($DCX).
            <h6>Click planet to explore</h6>
          </Tooltip>
          <Tooltip anchorSelect=".farm-tooltip" place="right" style={{ backgroundColor: "#FFF", color: "#222", width: "400px", zIndex: "999" }} clickable>
            <h6>Farm</h6>
            Welcome to the heart of D-Swap - the Farm. Farming on D-Swap is your ticket to
            earning passive income while contributing liquidity to our decentralized exchange.
            Add liquidity: Deposit an equal value of both tokens in the selected pool to become a
            liquidity provider (LP). Your LP tokens represent your share of the pool. our native
            governance token, as rewards, the more liquidity you provide for greater rewards.
          </Tooltip>
          <Tooltip anchorSelect=".StakeEarn-tooltip" place="right" style={{ backgroundColor: "#FFF", color: "#222", width: "400px", zIndex: "999" }} clickable>
            <h6>Stake & Earn</h6>
            Stake & Earn allows you to maximize your returns by staking tokens and earning
            additional rewards, Stake your tokens: Deposit your tokens into our staking contract
            to lock them up for a predetermined period. Earn rewards: By staking your tokens,
            you become eligible to receive even more rewards over time. These rewards may
            include additional our native tokens or other tokens supported by our platform.
          </Tooltip>
          <Tooltip anchorSelect=".CrosschainSwap-tooltip" place="left" style={{ backgroundColor: "#FFF", color: "#222", width: "400px", zIndex: "999" }} clickable>
            <h6>Cross chain</h6>
            Experience token swapping across different blockchain networks with D- Swap
            Cross-Chain feature. Cross-Chain Swap makes it easy to trade between different
            blockchain assets without leaving the platform. Our automated system handles the
            swap across the selected blockchain networks, providing you with the tokens you
            need in a matter of seconds.
          </Tooltip>
          <Tooltip anchorSelect=".lottery-tooltip" place="right" style={{ backgroundColor: "#FFF", color: "#222", width: "400px", zIndex: "999" }} clickable>
            <h6>Lottery</h6>
            A decentralized lottery system where participants can buy tickets for a chance to win prizes in a fair and transparent way.
            <h6>Click planet to explore</h6>
          </Tooltip>
          <Tooltip anchorSelect=".prediction-tooltip" place="right" style={{ backgroundColor: "#FFF", color: "#222", width: "400px", zIndex: "999" }} clickable>
            <h6>Prediction</h6>
            A prediction market platform that allows users to speculate on the outcomes of future events and earn rewards for accurate forecasts.
            <h6>Click planet to explore</h6>
          </Tooltip>
          <Tooltip anchorSelect=".promote-tooltip" place="left" style={{ backgroundColor: "#FFF", color: "#222", width: "400px", zIndex: "999" }} clickable>
            <h6>Promote & Refer (Earn)</h6>
            The "Promote & Refer (Earn)" initiative rewards users for spreading the word about D-Eco Project and bringing in new participants. It leverages community efforts to drive platform growth, offering rewards ($DCX) for referrals and promotional activities.
            <h6>Click planet to explore</h6>
          </Tooltip>
          <Tooltip anchorSelect=".dcx-tooltip" place="left" style={{ backgroundColor: "#FFF", color: "#222", width: "400px", zIndex: "999" }} clickable>
            <h6>BUY DCX</h6>
            DCX stands at the core of our digital ecosystem, offering a reliable and versatile cryptocurrency for trading, investing, and everyday transactions. As the foundational currency of our D-ecosystem, DCX ensures secure, efficient, and seamless operations across our blockchain network.
            <h6>Click planet to explore</h6>
          </Tooltip>
          <Tooltip anchorSelect=".dusd-tooltip" place="right" style={{ backgroundColor: "#FFF", color: "#222", width: "400px", zIndex: "999" }} clickable>
            <h6>DUSD</h6>
            DUSD presents a gold-backed stablecoin that combines the stability of traditional finance with the innovation of cryptocurrency. Offering a shield against the volatility of the crypto market, DUSD provides a secure and stable asset for transactions, savings, and investments.
          </Tooltip>
          <Tooltip anchorSelect=".PropelX-tooltip" place="right" style={{ backgroundColor: "#FFF", color: "#222", width: "400px", zIndex: "999" }} clickable>
            <h6>PropelX</h6>
            PropelX is a pioneering platform within our D-ecosystem, designed to multiply your cryptocurrency holdings through strategic networking. It offers a sophisticated yet accessible path for individuals to grow their digital assets, leveraging the collective power of our community.
          </Tooltip>
          <Tooltip anchorSelect=".DChain-tooltip" place="right" style={{ backgroundColor: "#FFF", color: "#222", width: "400px", zIndex: "999" }} clickable>
            <h6>D-CHAIN</h6>
            D-CHAIN is the technological backbone of our D-ecosystem, a cutting-edge blockchain protocol that enables the secure and efficient implementation of smart contracts and decentralized applications. It supports a wide range of enterprise solutions, from small to large scale, ensuring reliability, security, and scalability.
            <h6>Click planet to explore</h6>
          </Tooltip>
          <Tooltip anchorSelect=".Getx-tooltip" place="left" style={{ backgroundColor: "#FFF", color: "#222", width: "400px", zIndex: "999" }} clickable>
            <h6>Getx Exchange</h6>
            Getx is Our Partnership firm, a component of the ecosystem designed to facilitate
            the trading of digital assets. GetX aims to provide a secure and efficient trading
            experience, with a focus on liquidity and low-latency execution. Getx Exchange
            supports a wide range of cryptocurrencies and trading pairs, catering to the diverse
            needs of the crypto community.
          </Tooltip>
          <Tooltip anchorSelect=".defi-tooltip" place="right" style={{ backgroundColor: "#FFF", color: "#222", width: "400px", zIndex: "999" }} clickable>
            <h6>DeFi</h6>
            Decentralized finance DeFi, latest technology to eliminate intermediaries and centralized entities from financial transactions. Its core components include cryptocurrencies, blockchain technology, and specialized software, enabling direct financial interactions between individuals without the need for traditional intermediaries.
            <h6>Click planet to see DEFI APPS</h6>
          </Tooltip>


          <div className='d-world-top-0' style={{ animationDuration: "80s" }}>
            <div class="pos" style={{ animationDuration: "80s" }}>
              <img src={DAO} alt="DAO" onClick={toggleActiveClass} className='d-world-top-1-image-1 dao-tooltip position-absolute' style={{ left: "-4vh", top: "-4vh" }} />
              <a href="https://dao.d-ecosystem.io/lottery" target='_blank'><img src={Lottery} alt="Lottery" className={`d-world-top-1-image-1-2 lottery-tooltip position-absolute ${isActive ? "d-world-top-1-image-1-active" : ""}`} style={{ left: "4vh", top: "-5vh" }} /></a>
              <a href="https://dao.d-ecosystem.io/win" target='_blank'><img src={Prediction} alt="Prediction" className={`d-world-top-1-image-1-2 prediction-tooltip position-absolute ${isActive ? "d-world-top-1-image-1-active" : ""}`} style={{ left: "2vh", top: "4vh" }} /></a>
              <a href="https://dao.d-ecosystem.io/dashboard" target='_blank'><img src={Promote} alt="Promote" className={`d-world-top-1-image-1-2 promote-tooltip position-absolute ${isActive ? "d-world-top-1-image-1-active" : ""}`} style={{ left: "-2vh", top: "-9vh" }} /></a>

            </div>
            <div class="pos" style={{ animationDuration: "80s" }}>
              <img src={DeFI} alt="DeFI" onClick={toggleActiveClass2} className='d-world-top-1-image-1 defi-tooltip position-absolute' style={{ left: "-4vh", top: "-4vh" }} />
              <img src={CrosschainSwap} alt="CrosschainSwap" className={`d-world-top-1-image-1-2 CrosschainSwap-tooltip position-absolute ${isActive2 ? "d-world-top-1-image-1-active" : ""}`} style={{ left: "-9vh", top: "-5vh" }} />
              <img src={Farm} alt="Farm" className={`d-world-top-1-image-1-2 farm-tooltip position-absolute ${isActive2 ? "d-world-top-1-image-1-active" : ""}`} style={{ left: "-3vh", top: "6vh" }} />
              <img src={StakeEarn} alt="StakeEarn" className={`d-world-top-1-image-1-2 StakeEarn-tooltip position-absolute ${isActive2 ? "d-world-top-1-image-1-active" : ""}`} style={{ left: "-3vh", top: "-9vh" }} />
              <img src={Dswap} alt="Dswap" className={`d-world-top-1-image-1-2 dswap-tooltip position-absolute ${isActive2 ? "d-world-top-1-image-1-active" : ""}`} style={{ left: "-9vh", top: "3vh" }} />
            </div>
          </div>
          <div className='d-world-top-1' style={{ animationDuration: "60s" }}>
            <div class="pos" style={{ animationDuration: "60s" }}>
              <img src={Getx} alt="Getx" className='d-world-top-1-image-2 Getx-tooltip position-absolute' style={{ left: "-3vh", top: "-3vh" }} />
            </div>
            <div class="pos" style={{ animationDuration: "60s" }}>
              <a href="https://validator.d-ecosystem.io/" target='_blank'><img src={ValidatorTool} alt="ValidatorTool" className='d-world-top-1-image-2 validator-tooltip position-absolute' style={{ left: "-3vh", top: "-3vh" }} /></a>
            </div>
          </div>
          <div className='d-world-top-1 border-0 d-world-top-2-2222' style={{ animationDuration: "60s" }}>
            <div class="pos" style={{ animationDuration: "60s" }}>
              <img src={Dnft} alt="Dnft" className='d-world-top-1-image-2 dnft-tooltip position-absolute' style={{ left: "-3vh", top: "-3vh" }} />
            </div>
            <div class="pos" style={{ animationDuration: "60s" }}>
              <img src={PropelX} alt="PropelX" className='d-world-top-1-image-2 PropelX-tooltip position-absolute' style={{ left: "-3vh", top: "-3vh" }} />
            </div>
          </div>
          <div className='d-world-top-2 flex-lg-row' style={{ animationDuration: "40s" }}>
            <div class="pos" style={{ animationDuration: "40s" }}>
              <a href="https://www.d-wallet.io/" target='_blank'><img src={Dwallet} alt="Dwallet" className='d-world-top-1-image-3 dwallet-tooltip position-absolute' style={{ left: "-3vh", top: "-3vh" }} /></a>
            </div>
            <div class="pos" style={{ animationDuration: "40s" }}>
              <a href="https://dcx.d-ecosystem.io/" target='_blank'><img src={DCX} alt="DCX" className='d-world-top-1-image-3 dcx-tooltip position-absolute' style={{ left: "-3vh", top: "-3vh" }} /></a>
            </div>
          </div>
          <div className='d-world-top-2 border-0 d-world-top-3-3333' style={{ animationDuration: "40s" }}>
            <div class="pos" style={{ animationDuration: "40s" }}>
              <img src={DUSD} alt="DUSD" className='d-world-top-1-image-3 dusd-tooltip position-absolute' style={{ left: "-3vh", top: "-3vh" }} />
            </div>
            <div class="pos" style={{ animationDuration: "40s" }}>
              <a href="https://mainnet-explorer.d-ecosystem.io/" target='_blank'><img src={DChain} alt="DChain" className='d-world-top-1-image-3 DChain-tooltip position-absolute' style={{ left: "-3vh", top: "-3vh" }} /></a>
            </div>
          </div>
          <div className='d-world-top-4'>
            <img src={Dworld} alt="Dworld" className='d-world-top-1-image-4' />
          </div>


        </div>

        <div className='bottom-text-content-css'>
          <p className='mb-0'><span className='fw-bold'>1. D-Chain Network:</span> The sun at the center, illuminating secure transactions and privacy features.</p>
          <p className='mb-0'><span className='fw-bold'>2. DUSD Goldbacked Stablecoin:</span> A stable orbit for seamless value transfer.</p>
          <p className='mb-0'><span className='fw-bold'>3. D-Coin Crypto:</span> A fiery planet of digital currency, blazing with potential.</p>
          <p className='mb-0'><span className='fw-bold'>4. NFT Platform:</span> A nebula of unique tokens, waiting to be explored.</p>
          <p className='mb-0'><span className='fw-bold'>5. Daocenter:</span> A binary star system with lottery prediction games and reward centers.</p>
          <p className='mb-0'><span className='fw-bold'>6. Propelx MLM:</span> A distant comet, rewarding your journey through the cosmos.</p>
          <p className='mb-0'><span className='fw-bold'>7. D-Chain Validator Staking:</span> A gravitational pull for validators, securing our network.</p>
          <p className='mb-0'><span className='fw-bold'>8. Defi Center:</span> A lush garden of farming, staking, and swapping opportunities.</p>
          <p className='mb-0'><span className='fw-bold'>9. GETX Crypto Exchange:</span> A binary star system, where traders orbit at the speed of light. The world’s first discount broking crypto exchange</p>
        </div>
      </section>
    </div>
  );
}

export default App;
